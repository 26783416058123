import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { addSidepot, editSidepotDate, editSidepotHasQuantityLock, editSidepotName, editSidepotPrice, editSidepotQuantity, removeSidepot, updateSidepotOrder } from "../actions/sidepots.actions";
import { SidePot } from "src/app/core/interfaces/SidePot";
import { getEditSuccess } from "../actions/edit.actions";


export interface SidepotsState extends EntityState<SidePot> { }

export const sidepotsAdapter: EntityAdapter<SidePot> = createEntityAdapter<SidePot>({
    selectId: (sidepot: SidePot) => sidepot._id,
    sortComparer: (sidepot1: SidePot, sidepot2: SidePot) => sidepot1.orderId - sidepot2.orderId,
 });
export const initialSidepotsState: SidepotsState = sidepotsAdapter.getInitialState();
export const sidepotsReducer = createReducer(
    initialSidepotsState,
    on(addSidepot, (state, action) => sidepotsAdapter.addOne({ _id: action.id, eventClass: action.classId, orderId: action.orderId, name: '', price: 0, date: '', hasQuantityLock: false, quantity: 0}, state)),
    on(removeSidepot, (state, action) => sidepotsAdapter.removeOne(action.id, state)),
    on(editSidepotName, (state, action) => sidepotsAdapter.updateOne(action.update, state)),
    on(editSidepotPrice, (state, action) => sidepotsAdapter.updateOne(action.update, state)),
    on(editSidepotDate, (state, action) => sidepotsAdapter.updateOne(action.update, state)),
    on(editSidepotHasQuantityLock, (state, action) => sidepotsAdapter.updateOne(action.update, state)),
    on(editSidepotQuantity, (state, action) => sidepotsAdapter.updateOne(action.update, state)),
    on(updateSidepotOrder, (state, action) => sidepotsAdapter.updateMany(action.payload, state)),
    on(getEditSuccess, (state, { data } )=> sidepotsAdapter.addMany([...data.sidepots], state)),
);
export const { selectAll, selectEntities } = sidepotsAdapter.getSelectors();