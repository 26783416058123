import { createAction, props } from "@ngrx/store";

export const addSidepot = createAction(
    '[Sidepot] Add',
    props<{ id: string, classId: string, orderId: number }>()
);

export const removeSidepot = createAction(
    '[Sidepot] Remove',
    props<{ id: string }>()
);


export const removeSidepotSuccess = createAction(
    '[Sidepot] Remove Success',
    props<{ id: any; }>()
);

export const removeSidepotFailure = createAction(
    '[Sidepot] Remove Failure',
    props<{ error: string }>()
);


export const updateSidepotOrder = createAction(
    '[Sidepot] Update Order',
    props<{ payload: any }>()
);

export const editSidepotName = createAction(
    '[Sidepot] Edit Name',
    props<{ update: any }>()
);

export const editSidepotPrice = createAction(
    '[Sidepot] Edit Price',
    props<{ update: any }>()
);

export const editSidepotDate = createAction(
    '[Sidepot] Edit Date',
    props<{ update: any }>()
);

export const editSidepotHasQuantityLock = createAction(
    '[Sidepot] Edit Quantity Lock',
    props<{ update: any }>()
);

export const editSidepotQuantity = createAction(
    '[Sidepot] Edit Quantity',
    props<{ update: any }>()
);