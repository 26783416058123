import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/AppState';
import { getOrganization } from 'src/app/store/actions/organization.actions';
import { selectOrganization } from 'src/app/store/selectors/organization.selectors';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { updateEventInfo } from 'src/app/store/actions/event.actions';
import { selectEvent } from 'src/app/store/selectors/event.selectors';
import { getEditData, setEditStatus } from 'src/app/store/actions/edit.actions';
import { UserService } from 'src/app/core/services/user.service';
import { UPLOAD_REQUEST } from 'src/app/store/actions/logo.actions';
import { selectLogoState } from 'src/app/store/selectors/logo.selectors';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  public logo: string = "";
  public eventId: any = "";
  logoUploadProgress: any = 0;
  public organizationData: any;
  public logoState: any;
  eventData: FormGroup;

  config: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY'
  }

  dateTimeConfig: any = {
    openOnFocus: true,
    openOnClick: true,
    format: 'MM-DD-YYYY HH:mm:ss'
  }

  constructor(private store: Store<AppState>, private userservice: UserService, private _formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, public organizationService: OrganizationService) {
    this.eventData = this._formBuilder.group({
      logo: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      start: [''],
      end: [''],
      openingDateTime: [''],
      closingDateTime: [''],
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');

    this.userservice.getUser();

    if (this.router.url.split('/')[1] === "edit") {
      this.store.dispatch(getEditData({ id: this.eventId }));
      this.store.dispatch(setEditStatus({ status: true }));
    } else {
      this.store.dispatch(getOrganization({ id: this.eventId }));
    }


    this.store.pipe(select(selectLogoState))
      .pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.logoState = data;

        if (this.logoState?.status == "Complete") {
          this.store.dispatch(getEditData({ id: this.eventId }));
        }
      });


    this.store.pipe(select(selectOrganization))
      .pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.organizationData = data;
      });

    this.store.pipe(select(selectEvent))
      .pipe(takeUntil(this.destroy$)).subscribe(data => {

        if (data.logo) {
          this.logo = "https://api.myentryform.com/logo/" + data.logo;
        }

        this.eventData.patchValue(data);
      });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  continueToFormBuilder() {
    let momentDateOpening = moment(this.eventData.value['openingDateTime']);
    let utcOpeningDateTime = moment.utc(momentDateOpening).format();

    this.eventData.value['openingDateTime'] = utcOpeningDateTime;

    let momentDateClosing = moment(this.eventData.value['closingDateTime']);
    let utcClosingDateTime = moment.utc(momentDateClosing).format();

    this.eventData.value['closingDateTime'] = utcClosingDateTime;

    this.store.dispatch(updateEventInfo({ data: this.eventData.value }))
    this.router.navigate(['/form']);
  }

  uploadLogo(files: any) {

    if (files.length) {

      this.store.dispatch(UPLOAD_REQUEST({ id: this.eventId, file: files[0] }));

    }
  }

}
