import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Rollover } from "src/app/core/interfaces/Rollover";
import { addRollover, editRolloverDate, editRolloverLabel, editRolloverPrice, editRolloverRollTo, removeRollover, updateRolloverOrder } from "../actions/rollovers.actions";


export interface RolloversState extends EntityState<Rollover> { }

export const rolloversAdapter: EntityAdapter<Rollover> = createEntityAdapter<Rollover>({
    selectId: (rollover: Rollover) => rollover._id,
    sortComparer: (rollover1: Rollover, rollover2: Rollover) => rollover1.orderId - rollover2.orderId,
 });
export const initialRolloversState: RolloversState = rolloversAdapter.getInitialState();
export const rolloversReducer = createReducer(
    initialRolloversState,
    on(addRollover, (state, action) => rolloversAdapter.addOne({ _id: action.id, classId: action.classId, orderId: action.orderId, label: '', price: 0, date: '', rollTo: ''}, state)),
    on(removeRollover, (state, action) => rolloversAdapter.removeOne(action.id, state)),
    on(editRolloverLabel, (state, action) => rolloversAdapter.updateOne(action.update, state)),
    on(editRolloverPrice, (state, action) => rolloversAdapter.updateOne(action.update, state)),
    on(editRolloverDate, (state, action) => rolloversAdapter.updateOne(action.update, state)),
    on(editRolloverRollTo, (state, action) => rolloversAdapter.updateOne(action.update, state)),
    on(updateRolloverOrder, (state, action) => rolloversAdapter.updateMany(action.payload, state))
);
export const { selectAll, selectEntities } = rolloversAdapter.getSelectors();