import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationService } from './core/services/organization.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/helpers';
import { CommonModule } from '@angular/common';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { EventComponent } from './components/event/event.component';
import { BottomNavigationComponent } from './components/common/bottom-navigation/bottom-navigation.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationEffects } from './store/effects/organization.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { clearStateMetaReducer, reducers } from './store/reducers/AppState';
import { ConnectFormDirective } from './core/directives/connectform.directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { MomentPipe } from './core/pipes/moment/moment';
import { NotifierModule } from 'angular-notifier';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormComponent } from './components/form/form.component';
import { ClassesComponent } from './components/form/classes/classes.component';
import { AdditionalItemsComponent } from './components/form/additional-items/additional-items.component';
import { ClassComponent } from './components/form/classes/class/class.component';
import { ItemComponent } from './components/form/additional-items/item/item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateComponent } from './components/form/classes/class/date/date.component';
import { SidepotsComponent } from './components/form/classes/class/sidepots/sidepots.component';
import { SidepotComponent } from './components/form/classes/class/sidepots/sidepot/sidepot.component';
import { RolloversComponent } from './components/form/classes/class/rollovers/rollovers.component';
import { RolloverComponent } from './components/form/classes/class/rollovers/rollover/rollover.component';
import { FormEffects } from './store/effects/form.effects';
import { EditService } from './core/services/edit.service';
import { EditEffects } from './store/effects/edit.effects';
import { SidepotService } from './core/services/sidepot.service';
import { SidepotEffects } from './store/effects/sidepot.effects';
import { HeaderComponent } from './components/common/header/header.component';
import { UserService } from './core/services/user.service';
import { ClassEffects } from './store/effects/class.effects';
import { ClassService } from './core/services/class.service';
import { AdditionalItemService } from './core/services/additional-item.service';
import { AdditionalItemEffects } from './store/effects/additional-item.effects';
import { RolloverService } from './core/services/rollover.service';
import { RolloverEffects } from './store/effects/rollover.effects';
import { ReceiptPreviewComponent } from './components/receipt-preview/receipt-preview.component';
import { LogoEffects } from './store/effects/logo.effects';

@NgModule({
    declarations: [
        AppComponent,
        ConnectFormDirective,
        EventComponent,
        // EntryComponent,
        BottomNavigationComponent,
        MomentPipe,
        FormComponent,
        ClassesComponent,
        AdditionalItemsComponent,
        ClassComponent,
        ItemComponent,
        DateComponent,
        SidepotsComponent,
        SidepotComponent,
        RolloversComponent,
        RolloverComponent,
        HeaderComponent,
        ReceiptPreviewComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NotifierModule,
        DpDatePickerModule,
        CreditCardDirectivesModule,
        DragDropModule,
        StoreModule.forRoot(reducers, { metaReducers: [clearStateMetaReducer] }),
        EffectsModule.forRoot([OrganizationEffects, FormEffects, EditEffects, SidepotEffects, ClassEffects, AdditionalItemEffects, RolloverEffects, LogoEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        OrganizationService,
        EditService,
        SidepotService,
        ClassService,
        UserService,
        AdditionalItemService,
        RolloverService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
